<template>
  <nav class="breadcrumbs">
    <template v-for="(item,index) in breadcrumbs">
      <nuxt-link :data-index="index" :to="index<breadcrumbs.length-1 ? '/'+item.url : '#'"> {{
          item.title
        }}
      </nuxt-link>
      <img v-if="index < breadcrumbs.length-1" src="/static/img/icons/bread-arrow.svg">
    </template>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  gap: 4px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

a {
  font-size: 12px;
  color: $dark-black;

  //&[data-index="0"] {
  //  color: $dark-black;
  //}

  &:last-child {
    color: $light-black;
  }

  @include smd {
    font-size: 12px;
  }
}

img {
  @include smd {
    width: 10px;
  }
}
</style>
